/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Desi Karunia Sari
==============================================================================================*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');
@import "sass-lib";

body {color: #333;font-size: 12px;font-family: 'Open Sans', sans-serif;position: relative;overflow-x: hidden;}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
p {margin: 1px 0 15px; line-height: 20px;}
input, textarea, select {font-size: 12px;border: none;border: 1px solid #D8D8D8;padding: 10px;margin: 0;color: #666;  appearance: none;-webkit-appearance: none;-moz-appearance: none;}
input[type=checkbox], input[type=radio] {border-top-style: none;border-right-style: none;border-bottom-style: none;border-left-style: none;}
h1 { font-size: 60px; }
h2 { font-size: 48px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }
p  { font-size: 16px; }  

section { position: relative; overflow: hidden; }
.wrapper-large {width: 1570px;margin: 0 auto;}
.wrapper-wide {width: 100%; padding: 0px 30px; @include boxSizing(border-box); }
.wrapper-medium{width: 1170px;margin: 0 auto;}
.wrapper-small{width: 971px;margin: 0 auto;}
   
.btn-blue{}
body:-webkit-full-page-media {background: #FFF !important;}
/* global
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper-large:after, .wrapper-wide:after, .wrapper-medium:after, .wrapper-small:after,section:after,.content-right:after,.content-left:after{clear:both;display: block;content:" "}
.before_clear:before{clear:both;display: block;content:" "}
.a-left{text-align: left}
.a-right{text-align: right}
.a-center , .text-center {text-align: center}
.text-grey {color: #ccc; }
.text-blue {color: #0083c3; }
.italic { font-style: italic; }
.bold { font-weight: bold; }
.regular { font-weight: lighter; }
.fl { float: left; }
.fr { float: right; }
.clear { clear: both; }
.text-direct{
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    cursor: pointer;
    img{
        display: inline;
        position: relative;
        top: 7px;
        left: 5px;
        width: 25px;
    }
    &:hover{color: #046c9e;}
}  
.btn-white{
    width: 300px;
    display: inline;
    a{
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
    }
    img{
        position: relative;
        top: 5px;
        display: inline;
    }
}
@keyframes myMove {
  from { transform: scale(1.0,1.0); transform-origin: 50% 50%; }
  to { transform: scale(1.1,1.1); transform-origin: 50% 0%; }
}
@-webkit-keyframes myMove {
  from { -webkit-transform: scale(1.0,1.0); -webkit-transform-origin: 50% 50%; }
  to { -webkit-transform: scale(1.1,1.1); -webkit-transform-origin: 50% 0%;  }
}
@-o-keyframes myMove {
  from { -o-transform: scale(1.0,1.0); -o-transform-origin: 50% 50%; }
  to { -o-transform: scale(1.1,1.1); -o-transform-origin: 50% 0%;  }
}
@-moz-keyframes myMove {
  from { -moz-transform: scale(1.0,1.0); -moz-transform-origin: 50% 50%; }
  to { -moz-transform: scale(1.1,1.1); -moz-transform-origin: 50% 0%; }
}
@-ms-keyframes myMove {
  from { -ms-transform: scale(1.0,1.0); -ms-transform-origin: 50% 50%; }
  to { -ms-transform: scale(1.1,1.1); -ms-transform-origin: 50% 0%; }
}
/* header
----------------------------------------------------------------------------------------------*/
.search-result{
   input{
        outline: none;margin: 30px 0;
        &[type=search] {
            background: url(../images/material/search-active.png) no-repeat 4px center;padding: 9px 10px 9px 32px;width: 100%;border-radius: 5px;-webkit-transition: all .5s;-moz-transition: all .5s;transition: all .5s;cursor: pointer;position:relative;right: 0px;font-size: 33px;border:none;border-bottom: 1px solid #ccc;
            &:focus{
                width: 100%;background: #fff url(../images/material/search-active.png) no-repeat 4px center;border-color: #333;box-shadow: none;border-bottom: 4px solid #0083c3;
            }
        }
        &:-moz-placeholder{color: transparent;}
    } 
    .result-list{
        margin: 30px 0; border-bottom: 1px solid #ccc;color: #333;
        .title{font-weight: lighter;font-size: 24px; margin-bottom: 10px;}
        p{font-size: 16px;line-height: 24px;}
        &:hover{-webkit-transition: all .5s;-moz-transition: all .5s;transition: all .5s; color: #0083c3; }
    }
    .paging{
        text-align: center;margin-bottom: 30px;
        a{
            font-size: 23px;padding: 0 10px; color: #0083c3;
            &:hover,&.active{background:#0083c3;color: #fff;border-radius: 5px;}
        }
    }
    &.no-result .result-list{ border: none; min-height: 50vh; }
}
header{
    position: absolute; top: 0px; left: 0px; right: 0px; z-index: 10;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%,transparent 100%);

    .burger-slide{display: none;}
    .wrapper-large{
        padding: 0;border-bottom: 1px solid #fff; padding: 50px 0 15px 0; @include boxSizing(border-box); -webkit-transition: all .5s ease-in-out;-moz-transition: all .5s ease-in-out;transition: all .5s ease-in-out;
        .logo{
            margin: 0px;
            margin-top: -20px;
            margin-bottom: 20px;
            img.active{display: none;}
            img.white{display: block;}
            img.mob{display: none;}
        }
        ul.menu {
            li{
                display: inline-block; color: #fff;
                a{
                    font-weight: bold;
                    text-transform: uppercase;
                    color: #fff;
                    padding:10px 20px;
                    font-size: 16px;
                    margin: 0 5px;
                    &:hover, &.active{
                        background:#0083c3;
                        color:#fff;
                        border-radius: 7px;
                    } 
                }
                &.search-btn{
                    width: 20px;
                    border-right: 1px solid #fff;
                    padding: 10px;
                    padding-right: 30px;
                    cursor: pointer;        
                    img{width: 20px;margin-top: 0px;}    
                    img.active{display: none;}
                    img.white{display: inline;}
                     /*input{display: none}     */
                    input{
                        outline: none;
                        &[type=search] {
                            background: url(../images/material/search.png) no-repeat 4px center;border:none;padding: 12px 10px 10px 32px;width: 20px;border-radius: 5px;-webkit-transition: all .5s;-moz-transition: all .5s;transition: all .5s;cursor: pointer;position: absolute;right: 10px;position: relative;font-size: 16px;
                            &:focus{
                                width: 800px;background: #fff url(../images/material/search-active.png) no-repeat 4px center;border-color: #333;-webkit-box-shadow: 0 0 5px rgba(109,207,246,.5);-moz-box-shadow: 0 0 5px rgba(109,207,246,.5);box-shadow: 0 0 5px rgba(109,207,246,.5);right: 770px;
                            }
                        }
                        &:-moz-placeholder{color: transparent;}
                    }
                }
                &.lang {
                    font-size: 16px;
                        a{
                        padding:10px; font-weight: normal;
                        &.active,&:hover{
                            background:transparent; font-weight: bold;
                        }
                    }
                }
                
            }
        }
    }
    &.active{
        position: fixed;
        .wrapper-large{
            width: 100%;@include boxSizing(border-box);background:#fff;padding: 50px 0 15px 0;border-bottom: 1px solid #ccc; -webkit-transition: all .5s ease-in-out;-moz-transition: all .5s ease-in-out;transition: all .5s ease-in-out;
            .logo{
                margin-left: 165px;
                margin-top: -20px;
                margin-bottom: 20px;
                img.white{display: none;}
                img.active{display: block;}
                img.mob{display: none;}
            }
            ul.menu{
                margin-right: 170px;
                li{
                    display: inline-block; color: #fff;
                    a{
                        font-weight: bold;
                        text-transform: uppercase;
                        color: #333;
                        padding:10px 20px;
                        font-size: 16px;
                        margin: 0 5px;
                        &:hover, &.active{
                            background:#0083c3;
                            color:#fff;
                            border-radius: 7px;
                        }
                    }
                    &.search-btn{
                        width: 15px;
                        border-right: 1px solid #333;
                        padding: 5px;
                        padding-right: 15px;
                        cursor: pointer;
                        img{width: 20px;margin-bottom: -7px;}   
                        img.white{display: none;}
                        img.active{display: block;} 
                        input{
                            outline: none;
                            &[type=search] {
                                background: url(../images/material/search-active.png) no-repeat 4px center;border:none;padding: 12px 10px 10px 32px;width: 20px;border-radius: 5px;-webkit-transition: all .5s;-moz-transition: all .5s;transition: all .5s;cursor: pointer;position: absolute;right: 10px;position: relative;font-size: 16px;
                                &:focus{
                                    width: 800px;background-color: #fff;border:1px solid #333;right: 780px;box-shadow: none;
                                }
                            }
                            &:-moz-placeholder{color: transparent;}
                        }
                        }
                    &.lang {
                        font-size: 16px;
                        color: #333;
                            a{
                            font-weight: normal; padding:10px;
                            &.active,&:hover{
                                background:transparent; font-weight: bold; color: #333;
                            }
                        }
                    }
                }
            }
        }
    }
    
}


/* middle
----------------------------------------------------------------------------------------------*/
.landing{
    color: #FFF;
    position:relative;
    max-width:100%;
    overflow:hidden;
    .highlight-text{
        position:absolute; top: 50%; left:50%; margin:0 auto; z-index: 100; transform: translate(-50%, -50%); width: 100vw;text-align: center;
        h1{
            font-size: 60px;
            font-weight : lighter;
            text-transform: uppercase;
        }
    }
    .box .index-img{
        float:left;width:33.33%;height: 950px;position: relative;z-index: 1;
        .img-landing{
            position: absolute; height: 950px; width: 100%; overflow: hidden; left: 0;
                .bg{position: absolute; top: 0; bottom: 0; min-width: 100%;}
                filter: brightness(80%);
            }
        .desc-landing{
            position: absolute;
            bottom: 0px;
            padding: 50px;
            width:535px;
            z-index: 10000;
            @include transition(0.2s background ease-out); filter: brightness(90%); background: linear-gradient(to top, rgba(0, 0, 0, 0.65) 0%,transparent 100%); cursor: pointer;

            &:hover{
                background-color: #0083c3;
                cursor: pointer;

            }
            .title{
                font-size: 48px;
                font-weight : lighter;
                margin: 10px 0;
                &:parent{ background-color:#f00!important; }
            }
        }
        &.landing1 .img-landing .bg{left: 50%; @include transform(translateX(-50%));}    
        &.landing2 .img-landing .bg{left: 50%; @include transform(translateX(-50%));}   
        &.landing3 .img-landing .bg{right: 50%;  @include transform(translateX(50%));}   
    }
}
.latest-news{
    .box-latest-news{
        .box-news-title{
            float: left;
            width: 402px;
            min-height: 200px;
            padding: 113px 0px 154px 0;
            @include boxSizing(border-box);
            h2{
                font-size: 48px;
                margin-bottom: 20px;
                font-weight: lighter;
            }
            .subtitle{
                font-size: 25px;
                font-weight: bold;
                color: #cccccc;
            }
        }
        .box-news{
            float: left;
            width: 389px;
            min-height: 150px;
            padding: 0px 50px 0px 0px;
            margin-top: 110px ;
            @include boxSizing(border-box);
            &:hover{
                cursor: pointer;
            }
            .box-info{
                .overflowtex{ 
                    font-size: 16px; font-weight: normal; color: #333; width: 130px; overflow: hidden; text-overflow: ellipsis; display: -webkit-inline-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;position: relative; top: 3px;}
                span{
                    color: #999;
                    font-weight: lighter;
                }
            }
            .box-title{
                font-weight: normal; font-size: 25px; min-height: 60px; line-height: 30px; -webkit-line-clamp: 2; -webkit-box-orient:vertical; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;

            }
            .box-desc{
                font-size: 16px; color: #999999; line-height: 25px; overflow: hidden; text-overflow: ellipsis; display: -webkit-inline-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;
            }
            i{
                font-size: 16px;
                color: #0083c3;
            }
        }
    }
}
.homepage{
    color: #fff;
    .main-slider{

        .list{
            height: auto;
            overflow: hidden;
            position: relative;
            display: block;
            z-index: 12;
            &.slick-active img{
               /* -webkit-animation: myMove 8s 1 ease-in-out;
                  -moz-animation: myMove 8s 1 ease-in-out;
                  -o-animation: myMove 8s 1 ease-in-out;
                  -ms-animation: myMove 8s 1 ease-in-out;
                  animation: myMove 8s 1 ease-in-out;*/
            }
        }
        .content{
            position: absolute; bottom: 0px; padding: 104px 185px; width: 50%; z-index: 3;
            h1{
                font-weight: lighter;
                margin-bottom: 30px;
                font-size:61px;
                line-height: 70px;
            }
            p{
                font-weight: lighter;
                line-height: 30px;
                font-size: 20px;
            } 
            &.desc-center{  top: 35%;left: 30%; padding: 0; }
        }
        .slick-dots li{
            float: left;
            margin-top: -11.8px;
            button{
                font-size: 0; background-color: #fff; border: none; position: relative; bottom: 129px; left: 1640px; width: 20px; height: 20px;border-radius: 100%;margin-right: 10px;
            }
            &.slick-active button{
                background-color: #0083c3;
                border:1px solid #0083c3;
            }
        }
    }
}
.aksen{
    background-image: url(../images/material/aksen.png);
    min-height: 100px;
    background-repeat: no-repeat;
    background-position: 100% 10px;
}
.about-us{
    border-left: 350px solid #0083c3;padding: 220px 50px;padding-bottom: 108px;margin-top: -8px; overflow: initial;
    .content{
        .img-content{ 
            width: 35%;
            float: left;
            position: relative;
            margin-left: -16%;
            margin-right: 150px;
            img{
                width: 600px;
                border-radius: 5px;
                -webkit-box-shadow: -6px 35px 41px 10px rgba(0, 0, 0, 0.06);
                -moz-box-shadow: -6px 35px 41px 10px rgba(0, 0, 0, 0.06);
                box-shadow: -6px 35px 41px 10px rgba(0, 0, 0, 0.06);
                transition: all .5s ease-in-out;
                &:hover{transform: scale(1.1);}
            }
        }
        .desc-content{
            padding: 20px;
            .text-grey{font-size: 20px; margin:30px 0;color: #999}
            .logo-content{
                width: 139px;
            }
            .highlight-text{
                width: 70%;
                line-height: 55px;
                text-align: left;
                font-size: 39px;
            }
            .description{
                width: 68%;
                font-size: 20px;
                line-height: 30px;
                margin-top: 15px;
                color: #999;
                font-weight: lighter;
            }

        }
    }
}
.mid-content{
    padding: 100px 0;
    &.news-list{
        padding: 0;
        .middle{
            .news{ display: grid; width: 60%; }
        }
    }
    &.detail-profile{
        .middle{
            .content-left{ width:35%; display: none; }
            .content-right{ width: 100%; }
        }
    }
    .middle{
        margin: 0 auto;
        font-size: 20px;
        .name-project{
            font-weight: lighter;
        }
        h5{font-size: 20px;color: #999;}
        p{
            line-height: 26px;
            font-size: 16px;
            font-weight: lighter;
            a{
               color:#0083c3; 
            }
            &.headline{
                color: #fff;
            }
        }
        .box-content{
            width: 30%;
            float: left;
            margin-top: 20px;
            h3{font-size: 39px;width: 100%;line-height: 55px;font-weight: lighter;}
            p{
                width: 85%;font-size: 20px;line-height: 30pt;padding: 0 40px;
            }
            .text-direct{
                margin-left: 40px;
            }
        }
        .box-title{
            width: 40%;
        }
        .info{
            width: 35%;
            float: left;
            height: 650px;
            .title{
                /*margin-bottom: 20px;*/
            }
            h2{
                margin: 30px 0px;
                line-height: 55pt;
            }
        }
        .news{
            /*display: grid;*/
            margin-bottom: 100px; width: 15vw;
            .news-list{
                width: 100%;
                .date{
                    font-weight: lighter;
                    font-size: 16px;
                    color: #999;
                    margin-bottom: 10px;
                }
                a{
                    font-size: 13px;
                }
                h3{
                    margin: 20px 0;font-size: 28px;font-weight: lighter;color: #333;
                }
                p{
                    font-size: 16px;
                    line-height: 28px;
                    color: #787878;
                    font-weight: lighter;
                    width: 97%;
                }
                hr{
                    border: 0.5px solid #ccc;
                    margin: 30px 0;
                }
                .link-direct{
                    font-weight: bolder;
                    text-transform: uppercase;
                }
            }
            .news-list:last-child hr{
                border:none;
            }
        }
        .content-left{
            width: 50%;
            float: left;
            .image-cover img{
                border-radius:5px;
                width: 570px;
            }
            &:after{
                content: '';
                clear: both;
                display: block;
            }
            .title{
                font-weight: lighter;
                font-size: 39px;
                margin-bottom: 20px;
            }
            .content{
                color: #fff;
                font-size: 16px;
                width: 80%;
            }
            .headline-text{
                margin-top: 20px;
                line-height: 50px;
                font-weight: lighter;
                font-size: 39px;
                width: 90%;
            }
        }
        .content-right{
            width: 50%;
            float: right;
            &:after{
                content: '';
                clear: both;
                display: block;
            }
            .title{
                margin-bottom: 20px;
                font-weight: lighter;
                font-size: 39px;
            }
            .profile-name{
                font-weight: bold;
                    margin: 20px 0 10px 0;
            }
            .description{
                color: #666;
                line-height: 30px;
                font-weight: lighter;
                margin-top: 40px;
            }
            .content{
                color: #fff;
                font-size: 16px;
                width: 80%;
            }
            .description-text{
                margin-top: 30px;
                color: #333;
                font-weight: lighter;
                width: 80%;
            }
        }
        .name-project{
            font-weight: lighter;
        }
        .description{
            line-height: 30px;
            color: #666;
            margin-bottom: 70px;
        }
        .text-info{
            color:#ccc;
            font-weight: lighter;
            margin-bottom: 50px
        }
        .box-project{ 
            text-align: center;
            width: 100%;
            margin-top: -100px;
            .image-project{
                position: relative; width: 48%; display: inline-block; margin: 10px; border-radius: 5px; max-height: 315px; overflow: hidden;
                img{
                    filter: brightness(50%);
                }
                .content{
                    color: #fff;
                    position: absolute;
                    bottom: 0;
                    padding: 30px;
                    text-align: left;
                    width: 100%;
                    p{
                        font-style: italic;
                        font-weight: lighter;
                        color:#fff;
                    }
                    h3{
                        font-weight: lighter;
                        font-size: 39px;
                    }
                }
                &:hover img, &:hover .content{
                    filter: brightness(90%);
                    background: linear-gradient(to top, rgba(0, 0, 0, 0.65) 0%,transparent 100%);
                    cursor: pointer;
                }
            }
        }
        .box-news{
            text-align: center;
            width: 100%;
            .images{
                width: 25%;
                img{
                    border-radius: 5px;
                }
            }
        } 
        .highlight-text{
            line-height: 30px;
            margin: 20px 0;
            font-size: 39px;
            line-height: 54.59px;
        }
        .list-profile{
            margin-top: 100px;
            clear: both;
            /*&.fir-list{margin-top: 250px;}*/
            .profile{
                width: 280px;
                display: inline-block;
                padding-right: 20px;
                margin-right: 15px;
                @include boxSizing(border-box);
                .images{
                    max-height: 150px;
                    border-radius: 5px;
                    img{width: 273px; max-height: 150px; }
                }
                .position{
                    font-size: 13px; color: #ccc; line-height: 15px; min-height: 30px;
                }
                .name{
                    font-weight: lighter; font-size: 20px; margin-top: -10px; margin-bottom: 10px; line-height: 25px; overflow: hidden; display: -webkit-box; -webkit-line-clamp:2; -webkit-box-orient:vertical; min-height: 51px;
                }
                a{
                    text-transform: uppercase;
                    font-weight: bold;
                    color: #0083c3;
                    font-size: 16px;
                }
            }
        }
        .box-detail{
            padding-top: 30px;
            .detail-project{
                width: 47.9%; float: left; padding: 0 5px; @include boxSizing(border-box);border:1px solid #ccc;border-left: 0;border-right: 0;margin-right: 20px;padding: 20px 0;
                p{line-height: 20px}
                .title{font-size:13px;font-weight: lighter;color: #999;text-transform: uppercase;margin-bottom: 0;}
                .content{font-size:13px;font-weight: bold;margin: 0;text-transform: uppercase;}
            }
        }
        .news-title{
            font-size: 39px;
            font-weight: lighter;
            margin-top: 50px;
        }
        
    }
    .m0{
        padding: 0;
        margin-bottom: 50px;
    }
    &#csr{ padding-top: 100px; }
    &#csr .middle .description{
        margin-bottom: 20px;
    }
    ul,ol{
        list-style: unset; margin-left: 20px;
        li{ margin-bottom: 10px; font-size: 16px; font-weight: normal; }
    }
    &.wrapper-small{ padding-top: 60px; }
}
.homepage-project{
    p{font-weight: lighter;color: #999;}
    a{text-align: right;}
    .box-40{width: 40% !important}
    .box-20{width: 20% !important}
    .middle {
        .box-content{
            width: 100%;
            h3{  }
            p{ padding: 0; }
            .text-direct{ margin: 0; }
        } 
    }
}
.image-grid{
    margin-bottom: 130px;
    .middle{
        &:after{content: ""; clear: both; display: block;}
    }
}
/*.news-sec .middle .wrapper-medium .news-slide .polite .slick-track .slick-slide{ width: 5%; }*/
.news-slide{
    margin: 100px 0; clear: both; border-bottom: 1px solid #ccc;
    .news{
        display: inline-block;
        padding-right: 20px;
        @include boxSizing(border-box);
        margin-bottom: 0 !important;
        min-height: 300px;
        .images{
            max-height: 150px;
            overflow: hidden;
            border-radius: 5px;
            img{width: 100%}
        }
        .position{
            font-size: 13px;
            color: #ccc;
        }
        .name{
            font-weight: lighter; font-size: 20px; margin-top: -10px; line-height: 35px; overflow: hidden; text-overflow: ellipsis; display: -webkit-inline-box; -webkit-line-clamp: 2; -moz-line-clamp: 2; -webkit-box-orient: vertical; min-height: 70px; max-height: 70px;
        }
        a{
            text-transform: uppercase;
            font-weight: bold;
            color: #0083c3;
            font-size: 13px;
        }
    }
    .slick-list{border-bottom: 1px solid #ccc;}
    .slick-dots {
        float: right;position: relative;right: 50px;bottom: -25px;
        li{
            display: inline;
            button{background:transparent;box-shadow: none;border:none;font-size: 20px;font-weight: lighter;color: #0083c3;cursor: pointer;}
            &.slick-active button{background:transparent;box-shadow: none;border:none;font-size: 20px;font-weight: bold;color: #0083c3;cursor: pointer;}
        }
    }
    .nav-left{position: absolute;right:110px; bottom: -50px;cursor: pointer;}
    .nav-right{position: absolute;right:0px; bottom: -50px;cursor: pointer;}

}
.pagingInfo{
    position: absolute;bottom: 153px;right: 400px;letter-spacing: 5px;color: #0083c3;font-weight: bold;
    .nav-left{ float: right; margin-left: 15px; cursor: pointer; }
    .nav-right{ float: left; margin-right: 15px; cursor: pointer; }
    a{ vertical-align: middle; }
}
.box-grid{
    margin: 0 -5px;
    .grid{
        width: 33.333333333333%; float: left; padding: 0 5px; @include boxSizing(border-box);
        img{width: 100%;}
        .big-image{max-height: 430px; overflow: hidden;border-radius: 6px;}
        .medium-image img{
        }
        .small-image{
            width: 50%; float: left; max-height: 210px; overflow: hidden;
            img{margin: 10px 0;width: 96.9%;}
        }
    }
}
.box-video{
    margin-bottom: -30px;
    .slick-arrow.nav-right{
        position: absolute;
        right: 300px;
        bottom: 350px;
        cursor: pointer;
    }
    .slick-arrow.nav-left{
        position: absolute;
        right: 350px;
        bottom: 350px;
        cursor: pointer;
        z-index: 1;
    }
    .slick-dots{
        opacity: 0;
        display: inline-flex !important;
    }
    &.details{
        .slick-arrow.nav-right{
            position: absolute;
            right: 50px;
            bottom: 25px;
            cursor: pointer;
        }
        .slick-arrow.nav-left{
            position: absolute;
            right: 110px;
            bottom: 25px;
            cursor: pointer;
            z-index: 1;
        }  
    }
}
.video-project{
    margin-bottom: 50px;
    height: 800px;
    &.detail-project{margin-bottom: -170px;}
    .video-slider{
        .item{
            height: 670px;
            overflow: hidden;
            border-radius: 5px;
            .thumb{
                width: 100%; height: auto;
            }
            .cover-thumb{
                position: absolute;
                top: 25%;left: 0; right: 0; text-align: center;cursor: pointer;
                img{
                    display: block; margin: auto;width: 80px;
                }
                p{
                    color:#fff;
                    /*margin-left: -100px;*/
                    margin-top: 20px;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 28px;
                }
            }
            .desc-project{
                color:#fff;position: absolute; bottom: 50px;background-color: #0083c3; background-image: url(../images/material/bg-caption.png);
                background-repeat: no-repeat; background-position: 800px; left: 0; right: 0;  border-radius: 5px;  padding: 30px 100px 60px 100px;
                width: 1170px; height: auto; @include boxSizing(border-box); margin: 0 auto;

                .numb{
                    margin-bottom: 20px;
                    font-size: 20px;
                }
                span{
                    float: right;
                    img{
                        position: relative;
                        right: 0px;
                        padding-left: 30px;
                        display: inline;
                    }
                }
                hr{
                    margin: 20px 0;
                }
                .info-text{
                    width: 500px;
                    float: left;
                    margin-top: 40px;
                    h2{
                        font-weight: lighter;
                    }
                    p{
                        font-style: italic;
                        font-weight: lighter;
                        margin-top: 25px;
                    }
                }
                .description{
                    margin-top: 60px;
                    p{
                        line-height: 25px; font-size: 16px; margin-bottom: 25px;
                    }
                    .btn-white{
                        margin-right: 35px; 
                    }
                }
            }
            .desc-project-detail{
                position: absolute;display: none;
                bottom: 200px;
                color: #fff;
                width: 100%;
                border-top: 1px solid #fff;
                padding: 20px;
                .numb{
                    margin-bottom: -20px; width: 85%;
                }
                span{
                    float: right;
                    img{
                        position: relative;
                        right: 0px;
                        padding-left: 30px;
                    }
                }
            }
            .navigation{
                .arr_slide{ 
                    position: absolute; top: 0; right: 0; display: none;
                    a{
                        display: block;
                        &.prev{position: absolute; top: 25px; right: 150px;}
                        &.next{position: absolute; top: 25px; right: 100px;}
                    }
                }
            }
        }
    }
}
.static-pages{
    .main-banner{
        max-height: 600px;
        img{
            height: auto; width: 100%;
        }
    }
}
.box-profile{
    text-align: center;
    h5{
        font-size:20px;
        color: #999;
    }
    .box{
        width: 49%; border: 1px solid #ccc; display: inline-block; cursor: pointer; padding: 40px; text-align:center; margin-right: -3px; @include boxSizing(border-box);
        &:hover{
            border:1px solid #0083c3;
        }
        .profile-name{
            font-weight: bold; margin: 20px 0px; font-size: 39px; overflow: hidden; text-overflow: ellipsis; display: -webkit-inline-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; height: 85px; line-height: 40px;
        }
        .direct{
            font-weight: bold;
            color: #0083c3;
        }
        .profile-position{
            font-size:20px;color: #999;font-weight: lighter;margin-bottom: 50px;
        }
    }
}
.menu-id{
    border-bottom: 1px solid #ccc;  padding: 40px 0px 40px 0px; background-color: #f7f7f7;
    ul li{
        display: inline;
        text-transform: uppercase;
        padding-right: 30px;
        a{
            color: #333;
            padding-bottom: 34px; 
            font-size: 16px;
            &.active{
                font-weight: bold;
                border-bottom: 4px solid #0083c3;
            }
        }
        &.active{ display: none; }
    }
}
.blue-aksen{
    background-color: #0083c3;
    background-image: url(../images/material/bg-caption.png);
    background-repeat: no-repeat;
    background-position: -1000px -600px;
    background-size: 3000px;
    color: #fff;
}
.bg-grey{
    background: #f7f7f7;
}
.mid-history.history{
    .content-left{width: 50%}
    .content-right{
        width: 50%;
        padding: 20px 0;
        .date-title{
            margin-bottom: -20px;
            margin-top: 29px;
            font-size: 39px;
        }
    }
    .navigation{
        text-align: center; clear: both; position: relative;
        .item{
            display: inline-block;
        }
        hr{
            width: 500px;
        }
        
    }
}
.career{
    .middle{
        .name-project{
            margin: 30px 0;
        }
    }
}
.box-contact{
    padding: 100px 0;
    .address{position: relative;left: -130px;}
    .headline-text{font-size: 39px;font-weight: lighter;margin: 44px 0;}
    .form{
        margin-bottom: 146px;
        .field{
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-bottom: 20px;
            label{
                font-size: 13px;
                padding: 20px 33px;
                padding-right: 0px;
                color: #999;
                display: inline-block; width: 70px;
            }
            input{
                border: none;
                font-size: 20px;
                width: 440px;
                height: 36px;
                color: #0083c3;
            }
            textarea{
                border: none;
                font-size: 20px;
                width: 577px;
                height: 100px;
                color: #0083c3;
                resize: none;
                display: block;
                border-radius: 5px               
            }
            ::-webkit-input-placeholder { 
              color: #ccc;
              padding-left: 20px;
            }
            ::-moz-placeholder { 
              color: #ccc;
              padding-left: 20px;
            }
            :-ms-input-placeholder { 
              color: #ccc;
              padding-left: 20px;
            }
            :-moz-placeholder { 
              color: #ccc;
              padding-left: 20px;
            }
            input[type="submit"]{
                background: #0083c3;
                color: #fff;
                border-radius: 5px;
                width: 150px;
                padding:0;
                padding: 15px;
                height: auto;
                cursor: pointer;
                background-image: url(../images/material/right.png);
                background-repeat: no-repeat;
                background-position: 120px;
                padding-right: 50px;
                background-size: 7px;
            }
        }
    }
    .address{
        h3{
            margin: 30px 0;
            color: #333333;
            font-size: 39px;
        }
        p{
            line-height: 30px;
            font-weight: lighter;
        }
    } 
}
.cmap{
    height: 600px;
    width: 100%;
}
.history-slide .middle .content-right .description{
    margin-bottom: 0;
}
.mid-content.history{
    /*.history-slide{margin-bottom: 100px;}*/
    .history-nav{
        padding: 25px 0; background-color: #0083c3; color: #67b4da;border-radius: 5px; margin-top: 50px;
        .slick-dots li{display: none;}
        .nav-left{position: absolute;left: 53px; top: 25px;cursor: pointer;}
        .nav-right{position: absolute;right: 53px; top: 25px;cursor: pointer;}
        .slick-slider .slick-track, .slick-slider .slick-list{
            width: 914px;
            margin: 0 auto;
        }
        .item{
            position: relative;min-height: 100px;cursor: pointer;box-sizing: border-box;
            p{margin: 0 50px 10px 50px; text-align: center;color: #68b3d9; font-weight: normal;}
                &:before{border-top: 2px solid #67b4da;content:"";margin: 0 auto;position: absolute;bottom: 20px;width: 100%;z-index: -1;}
                &:after{content: ""; display: block;margin: 0 auto; width: 15px; height: 15px; background: #68b3d9; border-radius: 100%; position: relative; bottom: -9px;}
            &.slick-center{
                color: #fff;
                .history-year{font-weight: bold;font-size: 31px; color: #fff;}
                p{ color: #fff; }
                &:after{border:none; background:#fff;}
            }
            &.no-con{
                padding-top: 62px;min-height: inherit;
                &:before{border-top: 2px solid #67b4da;content:"";margin: 0 auto;position: absolute;bottom: 10px;width: 100%;z-index: -1;}
            }
            &.is-active{
                p{ color: #fff; }
                &:after{ background-color: #fff; }
                .history-year{ font-weight: bold; font-size: 29px; }
            }
        }
    }
    .middle{
        .content-right{
            width: 45%;
            .date-title{
                margin-bottom: -20px; margin-top: 29px; font-size: 39px;
            }
        }
    }
}
.nud-slider {
    img{border-radius: 5px;}
    .desc-project-detail{position: absolute;bottom: 15px;color: #fff;border-top:1px solid #fff;width: 100%;padding-left: 40px;padding-top: 25px;}
    .nav-left{position: absolute;bottom: 25px; right:90px;z-index: 2;cursor: pointer}
    .nav-right{position: absolute;bottom: 25px; right:40px;z-index: 2;cursor: pointer}
}
.news-detail{
    .name-project{font-size: 39px;color: #333;line-height: 54px;padding: 30px 0;padding-bottom: 0}
    .text-info{font-weight: lighter;font-size: 13px;color: #999;}
}
.mid-content.visionmission{
    padding: 150px;
    background-color: #0083c3;
    background-image: url(../images/material/bg-caption.png);
    background-repeat: no-repeat;
    background-position: 1200px -100px;
    background-size: 1500px;
    color: #fff;
    .headline{font-weight: bold}
    .content-left{
        width: 50%;
        .content{width: 90%}
    } 
    .content-right{width: 45%}
}
.project-detail{
    padding: 100px 0 50px 0;
}
.title-detail-project{margin-top: 70px;}
@-webkit-keyframes slideIn {
    from {top: -100px; opacity: 0} 
    to {top: 150; opacity: 1}
}

@keyframes slideIn {
    from {top: -100px; opacity: 0} 
    to {top: 150; opacity: 1}
}

@-webkit-keyframes fadeIn {
    from {opacity: 0} 
    to {opacity: 1}
}

@keyframes fadeIn {
    from {opacity: 0} 
    to {opacity: 1}
}
.modal{
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s;
    .modal-content{
        position: fixed;
        top: 150px;
        background-color: transparent;
        width: 63.5%;
        left: 45%;
        margin-left: -400px;
        -webkit-animation-name: slideIn;
        -webkit-animation-duration: 0.4s;
        animation-name: slideIn;
        animation-duration: 0.4s;
    }
    .close {
        position: absolute;
        color: #fff;
        font-size: 60px;
        font-weight: bold;
        top: 0px;
        right: 100px;
        z-index: 9;
        &:hover,&:focus{
            text-decoration: none;
            cursor: pointer;
        }
    }
    video{ height: 600px; width: auto; }
}
.project-maps{
    .gmnoprint a, .gmnoprint span, .gm-style-cc, .gm-fullscreen-control, .gmnoprint div{
        display:none;
    }
} 
.csr-slider{
    .slick-dots li{
        float: left;
        margin-top: -11.8px;
        button{
            font-size: 0; background-color: #fff; border: none; position: relative; bottom: 100px; left: 1050px; width: 20px; height: 20px; border-radius: 100%; margin-right: 10px;
        }
        &.slick-active button{
            background-color: #0083c3;
            border:1px solid #0083c3;
        }
    }
}
.slick-slide img{
    border-radius: 5px;
}
.html5-video-player{ height: 97%; }
.profile-slider{
    .nav-right{
        position: absolute;right: -30px;top: 80px;
    }
    .nav-left{
        position: absolute;left: -30px;top: 80px;
    }
}
/*.vid_web{ height: 620px;position: relative;
        video{height: auto;position: absolute;bottom: 0;left:0;right: 0;width: 100%;}
 }*/
.box-video.details{
    margin: 0; margin: auto;
    .video-project{
        &.detail-project{ margin: 0; height: auto; }
        .video-slider{
            .item{ 
                height: auto; overflow: hidden; border-radius: 5px; 
                .thumb{ position: absolute; top: 0; bottom:0; left: 0; right: 0; z-index: 1; object-position: center; height: 720px;}
                .cover-thumb{ z-index: 2; top: 35%; }
                .desc-project-detail{ bottom: 30px; display: block; z-index: 2;  filter: brightness(90%); background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%,transparent 100%);}

            }
        }
        .vid_web{ 
            height: 720px;
            video{ height: 720px; } 
        }
    }
}
#ytplayer{ position: relative; left: -8%; }
.modal .close{ right: 54px }
/* footer
----------------------------------------------------------------------------------------------*/
footer{
    background-color: #333333;
    height: 100px;
    padding: 40px;
    .info{
        margin-left: 150px;
        .logo{
            border:1px solid #f00;
        }
        .address{
            width: 500px;
            color: #8e8e8e;
            display: inline-block;
            margin-left: 100px;
            p{
                margin: 4px;
            }
        }
    }
    .menu-footer{
        margin-right: 100px;
        margin-top: 30px;
        color: #8e8e8e;
        ul li{
            display: inline;
            text-transform: uppercase;
            padding: 0 0 0 40px;
            a{
                font-size: 16px;
                color: #8e8e8e;
            }
        }
        p{
            float: right;
            text-transform: uppercase;
            margin-top: 15px;
            font-size: 13px;
            color: #666666;
        }
    }
    &.foo-land{
        background-color: #333333;
        text-align: center;
        color: #999999;
        padding: 34px;
        height: 20px;
        font-size: 14px;
    }
}

@media (min-width:1204px){
    /*.news-slide .news {width: 14vw !important;}*/
}
